/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { useEffect, useState } from 'react';
import {
  Alert,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ArchiveBox,
  ParentLayoutBox,
  StyledFormControl,
  StyledSwitch,
} from './noReportLayout.styled';
import DisplayTime from '../../../../../components/timezone.component';
import { sortByDateField } from '../../../../../utils/functions';

const translationJSONPrefix = 'newMarketIntelligencePage';

const NoReportLayout = ({
  status,
  stockKey,
  showArchives,
  handleShowArchives,
  allFinancialReports,
  reportData,
  handleReportChange,
  isGetAllFinancialReportsFetching,
}) => {
  const { t } = useTranslation();

  const [archiveData, setArchiveData] = useState({});
  const [latestReport, setLatestReport] = useState({});

  useEffect(() => {
    if (reportData) {
      const archive = allFinancialReports
        ?.filter((report) => report.status !== 'ERROR')
        ?.find(
          (report) => report?.lastUpdatedDate === reportData.lastUpdatedDate,
        );
      setArchiveData(archive || {});
    }

    if (allFinancialReports?.length > 0) {
      setLatestReport(
        sortByDateField([...allFinancialReports], 'lastUpdatedDate')?.[0],
      );
    }
  }, [reportData, allFinancialReports]);

  const handleChange = (event) => {
    const selectedDate = event.target.value;
    const selectedArchive = allFinancialReports.find(
      (report) => report.lastUpdatedDate === selectedDate,
    );
    setArchiveData(selectedArchive);
    handleReportChange(selectedArchive);
  };
  const statusConfig = {
    ARCHIVED_REPORT_ERROR: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.archivedReportError.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.archivedReportError.subtitle',
      ),
      severity: 'error',
    },
    ERROR: {
      title: t('newMarketIntelligencePage.reports.noReportLayout.error.title'),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.error.subtitle',
      ),
      severity: 'error',
    },
    CREATING: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.creating.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.creating.subtitle',
      ),
      severity: 'success',
    },
    PLANNED: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.planned.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.planned.subtitle',
      ),
      severity: 'info',
    },
    null: {
      title: t('newMarketIntelligencePage.reports.noReportLayout.null.title'),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.null.subtitle',
      ),
      severity: 'warning',
    },
    pending: {
      title: t(
        'newMarketIntelligencePage.reports.noReportLayout.pending.title',
      ),
      subtitle: t(
        'newMarketIntelligencePage.reports.noReportLayout.pending.subtitle',
      ),
      severity: 'warning',
    },
  };
  if (isGetAllFinancialReportsFetching) {
    return (
      <ParentLayoutBox>
        <LinearProgress />
      </ParentLayoutBox>
    );
  }
  const isLatestReport =
    reportData?.reportId === null ||
    latestReport?.id === archiveData?.id ||
    latestReport?.id === reportData?.id;

  return (
    <ParentLayoutBox>
      {allFinancialReports?.filter((report) => report.status !== 'ERROR')
        ?.length > 0 && (
        <ArchiveBox noValidate component="form">
          <FormControlLabel
            control={
              <StyledSwitch
                value={showArchives}
                checked={showArchives}
                onChange={handleShowArchives}
              />
            }
            label={t(`${translationJSONPrefix}.reports.switch.archives`)}
          />
          {showArchives && (
            <StyledFormControl>
              <InputLabel>Archives</InputLabel>
              <Select
                onChange={handleChange}
                size="small"
                label="Archives"
                value={archiveData?.lastUpdatedDate || ''}
              >
                {sortByDateField([...allFinancialReports], 'lastUpdatedDate')
                  ?.filter((report) => report.status !== 'ERROR')
                  .map((report, index) => (
                    <MenuItem
                      key={index}
                      value={report?.lastUpdatedDate}
                      disabled={
                        reportData?.lastUpdatedDate === report?.lastUpdatedDate
                      }
                    >
                      <Stack>
                        <Typography>{report?.key}</Typography>
                        <DisplayTime
                          time={report?.lastUpdatedDate}
                          format="ddd, D MMM YYYY HH:mm"
                        />
                      </Stack>
                    </MenuItem>
                  ))}
              </Select>
            </StyledFormControl>
          )}
        </ArchiveBox>
      )}
      <Typography sx={{ marginY: 2 }} variant="h4">
        {stockKey}
      </Typography>

      {isLatestReport ? (
        <>
          <Alert severity={statusConfig[status]?.severity || 'info'}>
            {statusConfig[status]?.title}
          </Alert>
          <Typography variant="caption" sx={{ marginTop: 2 }}>
            {statusConfig[status]?.subtitle}
          </Typography>
        </>
      ) : reportData?.error === null ||
        (reportData?.status !== 'ERROR' && status !== null) ? (
        <>
          <Alert severity={statusConfig['ARCHIVED_REPORT_ERROR'].severity}>
            {statusConfig['ARCHIVED_REPORT_ERROR'].title}
          </Alert>
          <Typography variant="caption" sx={{ marginTop: 2 }}>
            {statusConfig['ARCHIVED_REPORT_ERROR'].subtitle}
          </Typography>
        </>
      ) : status in statusConfig ? (
        <>
          <Alert severity={statusConfig[status].severity}>
            {statusConfig[status].title}
          </Alert>
          <Typography variant="caption" sx={{ marginTop: 2 }}>
            {statusConfig[status].subtitle}
          </Typography>
        </>
      ) : (
        <>
          <Alert severity={statusConfig['null'].severity}>
            {statusConfig['null'].title}
          </Alert>
          <Typography variant="caption" sx={{ marginTop: 2 }}>
            {statusConfig['null'].subtitle}
          </Typography>
        </>
      )}
    </ParentLayoutBox>
  );
};

NoReportLayout.propTypes = {
  status: PropTypes.string,
  stockKey: PropTypes.string,
  allFinancialReports: PropTypes.array,
  showArchives: PropTypes.bool,
  handleShowArchives: PropTypes.func,
  handleReportChange: PropTypes.func,
  reportData: PropTypes.object,
  isGetAllFinancialReportsFetching: PropTypes.bool,
};

export default NoReportLayout;
