/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React, { useEffect, useState } from 'react';
import {
  List,
  ListItemText,
  Box,
  Typography,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  IconButton,
  Tooltip,
  Badge,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Delete, QuestionAnswer, Sort } from '@mui/icons-material';
import MiReportsAutoComplete from '../autocomplete';
import { StyledButton, StyledListButton } from './miReportsSidebar.styled';
import DialogComponent from '../../../../../components/app.dialog';
import { useTranslation } from 'react-i18next';
import { reportStatusConfig } from '../../reportStatusConfig';
import DisplayTime from '../../../../../components/timezone.component';
import { MARKET_INTELLIGENCE_LISTED_FEATURE } from '../../../../../constants/marketIntelligence';

const translationJSONPrefix = 'newMarketIntelligencePage';

const MiReportsSidebar = ({
  myStockPreferences,
  reportKeys,
  isGetReportKeysSuccess,
  handleSelectReport,
  selectedReportId,
  onAskDbSelect,
  handleSelectSymbolChange,
  userInput,
  handleUserInput,
  isGetStockSymbolFetching,
  feature,
  reportIdentifier,
  handleDeletePreference,
  isDeletePreferenceLoading,
}) => {
  const { t } = useTranslation();

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [deleteTargetKey, setDeleteTargetKey] = useState('');
  const [sorted, setSorted] = useState(false);
  const [myPreferences, setMyPreferences] = useState([]);

  useEffect(() => {
    if (myStockPreferences) {
      if (sorted) {
        handleSort();
      } else {
        setMyPreferences(myStockPreferences);
      }
    }
  }, [myStockPreferences]);

  const handleFinalSubmit = () => {
    handleDeletePreference(deleteTargetKey);
    setAlertDialogOpen(false);
  };

  const handleDeletePreferenceSelect = (key) => {
    setDeleteTargetKey(key);
    setAlertDialogOpen(true);
  };

  const handleSort = () => {
    if (sorted) {
      setMyPreferences(myStockPreferences);
      setSorted(false);
    } else {
      const sortedArray = [...myStockPreferences].sort((a, b) =>
        (a.symbol || '').localeCompare(b.symbol || ''),
      );
      setMyPreferences(sortedArray);
      setSorted(true);
    }
  };

  return (
    <Box
      sx={{
        //   width: { xs: 250, lg: 320 },
        flexShrink: 0,
        height: '100%',
        borderRight: '1px solid #ccc',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DialogComponent
        open={alertDialogOpen}
        setOpen={setAlertDialogOpen}
        title={t(`${translationJSONPrefix}.reports.alertDialog.title`)}
        content={t(`${translationJSONPrefix}.reports.alertDialog.content`)}
        onSubmit={(e) => {
          handleFinalSubmit();
        }}
        isDeleteLoading={isDeletePreferenceLoading}
        cancelBtnLabel={t(
          `${translationJSONPrefix}.reports.alertDialog.buttons.cancel`,
        )}
        submitBtnLabel={t(
          `${translationJSONPrefix}.reports.alertDialog.buttons.yes`,
        )}
      />

      <Grid container p={2}>
        <Grid
          container
          item
          spacing={1}
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-evenly"
        >
          <Grid item>
            <Tooltip title="Sort Preferences">
              <IconButton onClick={handleSort}>
                <Sort />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item flexGrow={1}>
            {/* <MiReportsAutoComplete /> */}
            <MiReportsAutoComplete
              preferences={myStockPreferences}
              symbolsList={reportKeys}
              handleSelectSymbolChange={handleSelectSymbolChange}
              userInput={userInput}
              handleUserInput={handleUserInput}
              isGetStockSymbolFetching={isGetStockSymbolFetching}
              feature={feature}
            />
          </Grid>
        </Grid>
      </Grid>
      <List sx={{ padding: 0 }}>
        <ListItemButton
          sx={{ backgroundColor: 'secondary.light' }}
          onClick={onAskDbSelect}
        >
          <ListItemIcon>
            <QuestionAnswer sx={{ color: 'secondary.main' }} />
          </ListItemIcon>
          <ListItemText>Ask the database</ListItemText>
        </ListItemButton>
      </List>
      <List sx={{ flexGrow: 1, overflowY: 'auto', padding: 0 }}>
        {isGetReportKeysSuccess && (
          <>
            {myPreferences?.map((reportKey, idx) => (
              <StyledListButton
                key={idx}
                onClick={() => handleSelectReport(reportKey)}
                selected={
                  reportIdentifier === reportKey?.key ||
                  reportIdentifier === reportKey?.symbol
                }
              >
                <ListItemAvatar>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      reportKey.status in reportStatusConfig &&
                      reportStatusConfig[reportKey.status].icon && (
                        <Tooltip
                          sx={{ marginTop: 1 }}
                          title={reportStatusConfig[reportKey.status].title}
                        >
                          {reportStatusConfig[reportKey.status].icon}
                        </Tooltip>
                      )
                    }
                  >
                    <Avatar
                      sx={{ width: 28, height: 28 }}
                      src={
                        feature === MARKET_INTELLIGENCE_LISTED_FEATURE
                          ? reportKey?.logoUrl
                          : reportKey?.companyLogoURL
                      }
                    />
                  </Badge>
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <>
                      <Typography variant="subtitle1">
                        {reportKey?.symbol}
                      </Typography>
                      <Typography variant="body1">
                        {reportKey?.description || reportKey?.companyName}{' '}
                      </Typography>
                    </>
                  }
                  secondary={
                    reportKey?.status === 'READY' && (
                      <Typography variant="caption">
                        <DisplayTime
                          time={reportKey?.lastUpdatedDate}
                          format="ddd, D MMM YYYY HH:mm"
                        />
                      </Typography>
                    )
                  }
                />
                {selectedReportId === reportKey.id && (
                  <Tooltip title={'Delete'}>
                    <IconButton
                      //disabled={selectedReportId !== reportKey.id}
                      color="primary"
                      onClick={() =>
                        handleDeletePreferenceSelect(
                          reportKey?.key || reportKey?.symbol,
                        )
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </StyledListButton>
            ))}
          </>
        )}
      </List>
    </Box>
  );
};

MiReportsSidebar.propTypes = {
  myStockPreferences: PropTypes.array,
  handleSelectSymbolChange: PropTypes.func,
  userInput: PropTypes.string,
  handleUserInput: PropTypes.func,
  isGetStockSymbolFetching: PropTypes.bool,
  feature: PropTypes.string,
  reportKeys: PropTypes.array,
  isGetReportKeysSuccess: PropTypes.bool,
  handleDeletePreference: PropTypes.func,
  handleSelectReport: PropTypes.func,
  selectedReportId: PropTypes.string,
  onAskDbSelect: PropTypes.func,
  isDeletePreferenceLoading: PropTypes.bool,
  reportIdentifier: PropTypes.string,
};

export default MiReportsSidebar;
